import React, { useState, useContext } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link, navigate } from "gatsby";
import Pagination from "react-js-pagination";
import DriverSignUp from '../../containers/DriverSignUp/DriverSignUp';
import CountryContext from "../../context/CountryContext";
import { injectIntl } from "gatsby-plugin-intl"
import BannerNews from "../../components/BannerNewsRoom/BannerNews"
import './newsroom.scss'
// import "bootstrap/less/bootstrap.less";

const Newsrooms = ({ posts, totalCount = 1, postsPerPage, path = "blog-vn", pageNumber = 1, intl }) => {
  const [activePage, setActivePage] = useState(pageNumber)
  const Context = useContext(CountryContext)
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber)
    if (pageNumber == 1) return navigate(`/${languageCode}/${path}`, { replace: false })
    navigate(
      `/${languageCode}/${path}/${pageNumber}`, {
        replace: false
      }
    )
  }
  const languageCode = intl.locale || "en"
  return (
    <div className="newsroom">
      <BannerNews 
        fleetInfo={Context && Context.fleetInfo && Context.fleetInfo.fleet|| {}}
      />
      {
        intl.locale == "my-ms"
        ? <h1>Mengejar <span className="colorMain">GOJO</span> berita <span className="colorMain">terkini!</span></h1>
        : intl.locale == "my-zh"
          ? <h1>关注 <span className="colorMain">GOJO</span> 的新闻</h1>
          : <h1>Catch <span className="colorMain">GOJO</span> in the <span className="colorMain">News!</span></h1>
      }
      <div className="lineColor"></div>
      <Container className="h-container">
        <Row>
          {
            posts.length && posts.map((page) => {
              let urlBanner = page.featuredImage && page.featuredImage.node && page.featuredImage.node.sourceUrl || ''
              return (
                <Col md={4}>
                  <div className="newsroom__item">
                      <div className="newsroom__item__thumbnail">
                        <Link to={`/${languageCode}${page.uri}`}>
                          {urlBanner && <img src={urlBanner} />}
                        </Link>
                      </div>
                      <Link to={`/${languageCode}${page.uri}`}>
                        <h3>{page.title}</h3>
                      </Link>
                    <p className="date">{page.date}</p>
                  </div>
                </Col>
              )
            })
          }
        </Row>
        <Pagination
          hideDisabled
          activePage={activePage}
          itemsCountPerPage={postsPerPage}
          totalItemsCount={totalCount}
          pageRangeDisplayed={3}
          onChange={handlePageChange}
          itemClass="page-item"
          linkClass="page-link"
        />
      </Container>
      <DriverSignUp 
        countryCode={Context.country}
      />
    </div>
  )
}

export default injectIntl(Newsrooms)