import React from "react"
import { Link, graphql } from "gatsby"
import Newsroom from "../containers/newsroom/Newsroom"
import SEO from "../components/SEO/SEO"

const BlogIndex = ({
  data,
  pageContext: {
    nextPagePath,
    previousPagePath,
    postsPerPage,
    categories,
    pageNumber
  },
  ...props
}) => {
  const posts = data.malaysiaPost.nodes
  return (
    <div>
      <SEO 
        title = { "See the latest GOJO news" }
        description = {"Check out all the latest announcements, stories, app features and special promo offers from GOJO."}
      />
      <Newsroom 
        posts={posts}
        totalCount={data.malaysiaPost.totalCount}
        pageNumber={pageNumber}
        path={categories == 'VietNam' ? 'blog-vn' : 'blog-ms'}
        postsPerPage={postsPerPage}
      />
      {/* {previousPagePath && (
        <>
          <Link to={previousPagePath}>Previous page</Link>
          <br />
        </>
      )}
      {nextPagePath && <Link to={nextPagePath}>Next page</Link>} */}
    </div>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query WordPressPostArchive($offset: Int!, $postsPerPage: Int!, $categories: String!) {
    malaysiaPost: allWpPost(filter: {
        categories: {
          nodes: {
            elemMatch: {
              name: {
                eq: $categories
              }
            }
          }
        }
      }
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ){
      nodes {
        id
        title
        uri
        featuredImage {
          node {
            sourceUrl
          }
        }
        date(formatString: "dddd, DD MMMM y")
      }
      totalCount
    }
  }
`